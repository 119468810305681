import './App.css'

import {Placeholder} from './Components/Placeholder'

function App() {
    return (
        <>
            <Placeholder caption="Waiting..." text="Open soon"/>            
        </>
    )
}

export default App
