// FoxImage.js
import React from 'react'
import './Placeholder.css'

const FoxImage = ({ animationDuration }) => {
  return (
    <img id="fox" src="images/fox_logo_blink.png" alt="Fox" style={{ width: '1080px', height: '1024px' }} />
  )
};

export default FoxImage
