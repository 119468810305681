// App.js
import React, { useState, useEffect } from 'react'
import './Placeholder.css'
import FoxImage from './FoxImage'
import EyesImage from './EyesImage'

export const Placeholder = (props)=> {
  const [animationDuration, setAnimationDuration] = useState('3s')

  useEffect(() => {
    const randomDuration = getRandomDuration()
    setAnimationDuration(randomDuration);
  }, [])

  const getRandomDuration = () => {
    return Math.floor(Math.random() * (5 - 2 + 1) + 2) + 's'
  }

  const applyRandomDuration = () => {
    const randomDuration = getRandomDuration()
    setAnimationDuration(randomDuration)
  }

  return (
    <div id="container">
      <FoxImage animationDuration={animationDuration} />
      <EyesImage animationDuration={animationDuration} applyRandomDuration={applyRandomDuration} />
      <div id="caption"><h1>{props.caption}</h1></div>
      <div id="filling"><h1>{props.text}</h1></div>
    </div>
  )
}

