// EyesImage.js
import React from 'react'
import './Placeholder.css'

const EyesImage = ({ animationDuration, applyRandomDuration }) => {
  return (
    <img
      id="eyes"
      src="images/eyes.png"
      alt="Eyes"
      style={{ animation: `blink-animation ${animationDuration} infinite` }}
      onAnimationIteration={applyRandomDuration}
    />
  )
}

export default EyesImage
